//credit - https://codepen.io/luxonauta/pen/PoNOoqB
html,
body {
  // font-family: "Roboto", sans-serif;
  // cursor: crosshair;
  // background-color: #fbab7e;
  // animation: bg-color-change 2s linear infinite alternate both;
}

main {
  section {
    &.hero {
      // min-height: 100vh;

      .box-border {
        width: 100%;
        height: auto;
        padding: 0.125rem;
        overflow: hidden;
        position: relative;
        display: flex;
        // border-radius: 1rem;
        background: rgb(0, 237, 136);
        background: linear-gradient(
          90deg,
          rgba(0, 237, 136, 1) 0%,
          rgba(0, 214, 221, 1) 100%
        );
        box-shadow: 0 1.3125rem 2.72875rem 0.95875rem rgba(0, 0, 0, 0.24);

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            pointer-events: none;
            background: linear-gradient(
                rgba(#101010, 0.25) 50%,
                rgba(16, 16, 16, 0.1) 50%
              ),
              linear-gradient(
                90deg,
                rgba(255, 0, 0, 0.015),
                rgba(0, 255, 0, 0.01),
                rgba(0, 0, 255, 0.015)
              );
            background-size: 100% 0.1875rem, 0.375rem 100%;
            z-index: 2;
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            pointer-events: none;
            background: rgba(16, 16, 16, 0.2);
            animation: flicker 0.3s infinite;
            z-index: 2;
          }
        }

        .box {
          width: 100%;
          height: 100%;
          padding: 2rem;
          // border-radius: 1rem;
          background-color: black;

          .code {
            line-height: 1;
            font-family: "Source Code Pro", monospace;
            font-size: 0.8rem;
            color: #1ff042;

            .cursor-blink {
              animation: cursor-blink 0.6s infinite both;
            }
          }
        }
      }
    }
  }
}

// @keyframes bg-color-change {
//   0% {
//     background-color: #fbab7e;
//   }
//   100% {
//     background-color: #ff9a8b;
//   }
// }

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flicker {
  0% {
    opacity: 0.15795;
  }
  5% {
    opacity: 0.31511;
  }
  10% {
    opacity: 0.94554;
  }
  15% {
    opacity: 0.2469;
  }
  20% {
    opacity: 0.62031;
  }
  25% {
    opacity: 0.0293;
  }
  30% {
    opacity: 0.00899;
  }
  35% {
    opacity: 0.5344;
  }
  40% {
    opacity: 0.12778;
  }
  45% {
    opacity: 0.52042;
  }
  50% {
    opacity: 0.3823;
  }
  55% {
    opacity: 0.2198;
  }
  60% {
    opacity: 0.9383;
  }
  65% {
    opacity: 0.86615;
  }
  70% {
    opacity: 0.68695;
  }
  75% {
    opacity: 0.55749;
  }
  80% {
    opacity: 0.96984;
  }
  85% {
    opacity: 0.0361;
  }
  90% {
    opacity: 0.24467;
  }
  95% {
    opacity: 0.08351;
  }
  100% {
    opacity: 0.54813;
  }
}
