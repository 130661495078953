.text, .text-second, .a-heading, .i-heading {
  font-size: 48px;
}
.text, .text-second {
  color: #FAFAFA;
}
.a-heading, .i-heading {
  color: green;
  font-weight: bolder;
}
.a-bracket, .i-bracket {
  color: black !important;
  font-weight: bolder;
}
.text, .text-second {
  margin-left: 0px;
}
.dud {
  color: #757575;
}
.wording {
  margin: 10px;
  display: flex;
  font-family: monospace;
  filter: drop-shadow(3px 3px 3px black);
  -webkit-filter: drop-shadow(3px 3px 3px black);
}

@media only screen and (max-width: 779px) {
  .text, .text-second, .a-heading, .i-heading {
    //font-size: 21px;
    font-size: calc(1.6*(1vh + 1vw));
  }
}