@import '../../static/styles/base/colors';
@import '../../static/styles/base/variables';


body.dark-mode {
  .header__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // height: calc(100% - $hero-bottom-border);
    background-image: linear-gradient( to bottom, rgba($dm-main-color,0) 70%, rgba($dm-main-color,1) ), url('./cornfield.png');
    background-size: cover;
    background-position-x: center;
  }
}

header {
  position: relative;
  height: 800px;
  overflow: hidden;
}

.header__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // height: calc(100% - $hero-bottom-border);
  background-image: linear-gradient( to bottom, rgba($cultured,0) 70%, rgba($cultured,1) ), url('./cornfield.png');
  background-size: cover;
  background-position-x: center;
}


.header__bg__none {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
}